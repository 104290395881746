import React, { Component, Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import { ROUTES } from './routesEnam'
import { QueryClient, QueryClientProvider } from 'react-query'

// Containers
const AddIngredient = React.lazy(
  () => import('./views/ingredients/AddIngredient'),
)
const EditIngredient = React.lazy(
  () => import('./views/ingredients/EditIngredient'),
)

const AddOrder = React.lazy(() => import('./views/orders/AddOrder'))
const Order = React.lazy(() => import('./views/orders/Order'))
const EditStory = React.lazy(() => import('./views/stories/EditStory'))
const Story = React.lazy(() => import('./views/stories/Story'))
const Stories = React.lazy(() => import('./views/stories/Stories'))
const AddStory = React.lazy(() => import('./views/stories/AddStory'))
const AddBank = React.lazy(() => import('./views/bank/AddBank'))
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Login = React.lazy(() => import('./views/auth/Login'))
const Push = React.lazy(() => import('./views/push/EditCampaign'))
const Users = React.lazy(() => import('./views/users/Users'))
const About = React.lazy(() => import('./views/about/About'))
const Organizations = React.lazy(
  () => import('./views/organizations/Organizations'),
)
const AddLayer = React.lazy(() => import('./views/layers/AddLayer'))
const EditLayer = React.lazy(() => import('./views/layers/EditLayer'))
const Products = React.lazy(() => import('./views/products/Products'))
const EditProduct = React.lazy(() => import('./views/products/EditProduct'))
const AddProduct = React.lazy(() => import('./views/products/AddProduct'))
const Decoration = React.lazy(() => import('./views/decoration/Decoration'))
const Ingredients = React.lazy(() => import('./views/ingredients/Ingredients'))
const Size = React.lazy(() => import('./views/size/Size'))
const AddSize = React.lazy(() => import('./views/size/AddSize'))
const EditSize = React.lazy(() => import('./views/size/EditSize'))
const Orders = React.lazy(() => import('./views/orders/Orders'))
const Layers = React.lazy(() => import('./views/layers/Layers'))
const Promotions = React.lazy(() => import('./views/promotions/Promotions'))
const Promotion = React.lazy(() => import('./views/promotions/Promotion'))
const AddPromotion = React.lazy(() => import('./views/promotions/AddPromotion'))
const AddDecoration = React.lazy(
  () => import('./views/decoration/AddDecoration'),
)
const EditDecoration = React.lazy(
  () => import('./views/decoration/EditDecoration'),
)
const CreateOrganizations = React.lazy(
  () => import('./views/organizations/CreateOrganizations'),
)
const Banks = React.lazy(() => import('./views/bank/Banks'))
const EditBank = React.lazy(() => import('./views/bank/EditBank'))
const Product = React.lazy(() => import('./views/products/Product'))
export const queryClient = new QueryClient()

const App = (): JSX.Element => {
  function RequireAuth({ children }: any) {
    if (!localStorage.token) {
      return <Navigate to="/login" />
    }
    return children
  }
  if (localStorage.getItem('language') === null) {
    localStorage.setItem('language', 'en')
  }

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route path="/" element={<DefaultLayout />}>
              <Route index element={<Navigate to="/dashboard" />} />
              <Route path="*" element={<Navigate replace to="/dashboard" />} />
              <Route
                path="/push"
                element={
                  <RequireAuth>
                    <Push />
                  </RequireAuth>
                }
              />
              <Route
                path="/users"
                element={
                  <RequireAuth>
                    <Users />
                  </RequireAuth>
                }
              />
              <Route
                path="/stores"
                element={
                  <RequireAuth>
                    <Organizations key="2" />
                  </RequireAuth>
                }
              />
              <Route
                path="/add-store"
                element={
                  <RequireAuth>
                    <CreateOrganizations key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_BANK}
                element={
                  <RequireAuth>
                    <AddBank key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.DECORATION}
                element={
                  <RequireAuth>
                    <Decoration key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.SIZE}
                element={
                  <RequireAuth>
                    <Size key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.PROMOTIONS}
                element={
                  <RequireAuth>
                    <Promotions key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.PROMOTION + ':id'}
                element={
                  <RequireAuth>
                    <Promotion key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_PROMOTION}
                element={
                  <RequireAuth>
                    <AddPromotion key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_LAYER}
                element={
                  <RequireAuth>
                    <AddLayer key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.EDIT_LAYER + ':id'}
                element={
                  <RequireAuth>
                    <EditLayer key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_PRODUCT_CONTAINS}
                element={
                  <RequireAuth>
                    <AddIngredient key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_DECORATION}
                element={
                  <RequireAuth>
                    <AddDecoration key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.EDIT_DECORATION + ':id'}
                element={
                  <RequireAuth>
                    <EditDecoration key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.EDIT_PRODUCT + ':id'}
                element={
                  <RequireAuth>
                    <EditProduct key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.EDIT_INGREDIENT + ':id'}
                element={
                  <RequireAuth>
                    <EditIngredient key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.PRODUCT + ':id'}
                element={
                  <RequireAuth>
                    <Product key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.EDIT_SIZE + ':id'}
                element={
                  <RequireAuth>
                    <EditSize />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.LAYERS}
                element={
                  <RequireAuth>
                    <Layers key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_SIZE}
                element={
                  <RequireAuth>
                    <AddSize key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.INGREDIENTS}
                element={
                  <RequireAuth>
                    <Ingredients key="3" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ORDERS}
                element={
                  <RequireAuth>
                    <Orders key="1" />
                  </RequireAuth>
                }
              />

              <Route
                path={ROUTES.ORDER + ':id'}
                element={
                  <RequireAuth>
                    <Order />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_ORDER}
                element={
                  <RequireAuth>
                    <AddOrder key="1" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.STORIES}
                element={
                  <RequireAuth>
                    <Stories key="2" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_STORIES}
                element={
                  <RequireAuth>
                    <AddStory key="2" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ADD_PRODUCT}
                element={
                  <RequireAuth>
                    <AddProduct key="2" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.PRODUCTS}
                element={
                  <RequireAuth>
                    <Products key="2" />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.DECORATION}
                element={
                  <RequireAuth>
                    <Decoration key="2" />
                  </RequireAuth>
                }
              />
              <Route
                path="/about"
                element={
                  <RequireAuth>
                    <About />
                  </RequireAuth>
                }
              />
              <Route
                path="/banks"
                element={
                  <RequireAuth>
                    <Banks />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.STORY + ':id'}
                element={
                  <RequireAuth>
                    <Story />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.EDIT_BANKS + ':id'}
                element={
                  <RequireAuth>
                    <EditBank />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.EDIT_STORY + ':id'}
                element={
                  <RequireAuth>
                    <EditStory />
                  </RequireAuth>
                }
              />

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
            </Route>
          </Routes>
        </Suspense>
      </QueryClientProvider>
    </Router>
  )
}

export default App
