export enum ROUTES {
  MAIN = '/',
  BANKS = '/banks',
  EDIT_BANKS = '/banks/edit-bank/',
  ADD_BANK = '/banks/add-bank',
  STORIES = '/stories',
  STORY = '/stories/story/',
  EDIT_STORY = '/stories/edit-story/',
  ADD_STORIES = '/stories/add-story',
  ORDERS = '/orders',
  ORDER = '/orders/order/',
  ADD_ORDER = '/orders/add-order',
  EDIT_ORDER = '/orders/edit-order/',
  ADD_PROMOTION = '/promotions/add-promotion',
  PROMOTION = '/promotions/promotion/',
  PROMOTIONS = '/promotions',
  PRODUCTS = '/products',
  EDIT_PRODUCT = '/products/edit-product/',
  ADD_PRODUCT_CONTAINS = '/products/product-contains',
  ADD_PRODUCT = '/products/add-product',
  DECORATION = '/products/add-product/decoration',
  ADD_DECORATION = '/products/add-product/decoration/add-decoration',
  EDIT_DECORATION = '/products/add-product/decoration/edit-decoration/',
  INGREDIENTS = '/products/add-product/ingredients',
  PRODUCT = '/products/product/',
  SIZE = '/products/add-product/size',
  ADD_SIZE = '/products/add-product/size/add-size',
  EDIT_SIZE = '/products/add-product/size/edit-size/',
  LAYERS = '/products/add-product/layers',
  ADD_LAYER = '/products/add-product/layers/add-layer',
  EDIT_LAYER = '/products/add-product/layers/edit-layer/',
  ADD_INGREDIENT = '/products/add-product/ingredients/add-ingredient',
  EDIT_INGREDIENT = '/products/add-product/ingredients/edit-ingredient/',
}
